import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
/**
 * flex布局容器 包含安全区1040 和 底部文案
 */
export default defineComponent({
    name: 'Layout',
    props: {
        footer: {
            // 是否显示底部文案
            type: Boolean,
            require: false,
            default: true,
        },
    },
    setup() {
        const { t } = useI18n();
        return { t };
    },
});
